@import '../../../Styles/variables';
.formBtn{
    background-color: $--primary-color;   
    width: 300px;
    height: 60px;
    margin: 0 0 10px;
    align-self: center;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    margin:10;
    
    h6{
        font-size: .8rem;
        font-weight: bold;
        color: #fff; 
        text-align: center;
        text-transform: uppercase;   
    }
    .formBtn-imgWrapper{                
        overflow: hidden;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 40px;
            transform: rotateZ(90deg);
        }
    }   
}