@import '../../Styles/variables';

.reward {
    display: flex;   
    flex-direction: column;    
   align-items: flex-start;
    .reward-rewardsarea{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: center;
        @media only screen and (min-width: $--bpt-01) {  
            flex-direction: row;
            max-width: 1200px;
            width: 90vw;
            flex-wrap: wrap;
            
        }

    } 

    .reward-header {
        box-sizing: border-box;
        width: 100vw;
        height: 120vh;
        padding: 0 0 140px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: -100px;
        background-position-x: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        @media only screen and (min-width: $--bpt-01) {  
            background: transparent linear-gradient(180deg, #FF6200 0%, #FF8900 100%) 0% 0% no-repeat padding-box !important;
            flex-direction: row;
            height: 60vh;          
            justify-content: space-around;
            padding:0 40px 0 0;
            border-radius: 0 0 70px 70px;
         }
         .reward-headercontent{  
             box-sizing: border-box;  
             padding-top: 0px;        
             height:100%;
             width: 100%;
             display: flex;
             flex-direction: column;
             justify-content: space-around;
             align-items: center;   
             @media only screen and (min-width: $--bpt-01){
               justify-content: space-around;
               height: 90%;
               margin-top: 20px;
            }                              

         }

        .reward-nexaSipatLogo {
            width: 80%;
            margin-top: 5px;
            @media only screen and (min-width: $--bpt-01){
                width: 20%;
                margin-top: 0;
            }
        }

        .reward-nexaSipatRewards {
            width: 80%;
            @media only screen and (min-width: $--bpt-01){
               display: none;
            }
        }
        .reward-nexaSipatRewardsout{
            display: none;
            @media only screen and (min-width: $--bpt-01){
                margin-top: 70px;
                display: initial;
                width: 40%;
             }
        }

        .reward-title {
            color: #fff;
            font-family: $--primary-font;
            font-weight: 900;
            font-size: 3.5rem;
            margin: 0;
            margin: -65px 0 -15px;
            @media only screen and (min-width: $--bpt-01){
                font-size: 5rem;
                margin-top: 0;
             }
        }

        .reward-p1 {
            color: #fff;
            margin: 0;
            margin-top: 5px;
            width: 180px;
            @media only screen and (min-width: $--bpt-01){
                width: 90%;
            }
        }

        .reward-p2 {           
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            color: $--primary-color;
            margin: 10px 0 -15px;
            padding:0 20px;
            min-height: 65px;
            max-width: 80%;
            text-align: center;
            border-radius: 10px;
            font-weight: bold;
            font-size: .95rem;
           
        }

        .reward-scrollBtn {
            @media only screen and (min-width: $--bpt-01) {  
                display: none;
            }

            .reward-scrollText {
                margin: 5px;
                color: #fff;
                font-family: $--primary-font;
                font-size: .8rem;
            }

            .reward-scrollBtn {
                width: 60px;
            }
        }

    }

    .reward-rewardsarea {
        width: 100vw;
        padding: 20px 0;
    }

    .reward-date {
        width: 95%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media only screen and (min-width: $--bpt-01) {
            justify-content: flex-end;
            width: 92%;
         }
      

        .reward-dateinfo {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $--primary-color;
            width: 290px;
            height: 80px;
            border: solid 3px #FF6505;
            border-radius: 20px;
            background: #F0F0F0 0% 0% no-repeat padding-box;
            box-shadow: 0px 0px 15px #00000042;
            font-weight: bold;
            font-family: $--primary-font;
            @media only screen and (min-width: $--bpt-01) {
                width: 400px;
                height: 150px;
                font-size: 1.2rem;
             }
        }

    }

    .reward-footer {
        box-sizing: border-box;
        width: 100vw;
        height: 95vh;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 90px 0 0;
        margin-top: 50px;
        background-position-x: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @media only screen and (min-width: $--bpt-01) {        
          background:#1C1C1C !important;       
          flex-direction: row-reverse;
          height:60vh ;
          padding: 0 30px 20px 10px;
          justify-content: space-around;
          border-radius: 70px 70px 0 0;
        }
        .reward-footerinfo{         
            display: flex;
            flex-direction: column;
            padding-right: 10px;
            justify-content: flex-start;
          
           @media only screen and (min-width: $--bpt-01) {   
            align-self: flex-start;
            margin-top: 100px;
           }
          
        }
        

        .reward-footerdowninfo {
            color: $--primary-color;
            font-family: $--primary-font;
            font-weight: bold;
            font-size: 1.2rem;
            @media only screen and (min-width: $--bpt-01) {     
                font-size: 1.6rem;
            }
        }

        .reward-downBtnArea{
           display: flex;
           justify-content: center;
           align-items: center;
        }

        .reward-downBtn {
            background-color: $--primary-color;
            width: 300px;
            height: 60px;
            margin: 0 0 10px;        
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            cursor: pointer;

            h6 {
                font-size: 1rem;
                font-weight: bold;
                color: #fff;
                text-transform: uppercase;
            }

            .reward-btnimgWrapper {
                overflow: hidden;
                width: 20px;
                display: flex;
                justify-content: center;
              

                img {
                    width: 50px;
                    transform: rotateZ(90deg);
                }
            }
        }

        .reward-appexibition {
            width: 80%;
            @media only screen and (min-width: $--bpt-01) {   
                width: 50%;
                position: relative;
                top: -80px;
             }
        }

        .reward-storeiconsarea {
            display: flex;
            flex-direction: column;
            margin: 10px 0;

            .reward-storeicons {
                .reward-storeicon {
                    width: 50px;
                    margin: 0 15px;
                }
            }
            .reward-iconstext{
                color: $--primary-color;
                font-family: $--primary-font;
                font-weight: lighter;
                font-size: .7rem;
            }
            
        }

    }
    .jnnAyS {
        margin:  20px 0 10px !important;
    }
    .gfuSqG,.cOzEdO,.cOzEdO svg,.gfuSqG svg {  
        color: #FFF !important;
    }
    .cOzEdO,.gfuSqG { 
        &::before {
            border-color: #fff !important;
          }
          &::after {
            border-color: #fff !important;
            color: #fff;
        }
    }
    .reward-btnsDownload{
        align-self: center;
        display: flex;
        flex-direction: row;   
        margin-top: 10px;        
        .downloadBtnLeft_mobile{
            margin: 0 8px;
        
          
             background-color: $--primary-color;
             &:hover {           
                 background-color: rgb(249, 132, 77); 
             }
         
         }
    }
      
}
