@import '../../Styles/variables';

.winnersPodiumCard {
    background-color: $--primary-color;
    background-position: center;
    height: 65vh;
    max-height: 550px;
    width: 90vw;
    max-width: 270px;
    margin: 44px 10px 0;
    border-radius: 30px;
    flex-shrink: 0;
    padding: 0 10px;
   
    @include displayFlex(column, center, center);

    

    @media only screen and (min-width: $--bpt-01) {
        align-self: flex-end;         
        height: 52vh;
        min-height: 380px;
    }


    .winnersPodiumCard-rank {
        @include displayFlex(row, space-between, flex-start);
        width: 100%;
        height: 26%;


        .winnersPodiumCard-positionTitle {
            color: #fff;
            font-weight: 900;
            font-size: 6rem;
        }

        .winnersPodiumCard-imgWrapperTrophy {
            align-self: flex-end;
            width: 34%;
            height: 240px;
            max-height: 32vh;


            @include displayFlex(row, center, center);

            .winnersPodiumCard-imgTrophy {
                top: 40px;
                position: relative;
                height: 100%;
               


            }

        }
    }

    .winnersPodiumCard-userData {
        width: 100%;
        height: 34%;
        @include displayFlex(column, space-around, center);

        .winnersPodiumCard-imgWrapperAvatar {
            @include displayFlex(column, center, center);
            margin: 0;
            // height: 80px;
            height: 10vh;
            width: 10vh;
            border: 4px solid #C94D00;
            background-color: rgb(223, 223, 223);
            border-radius: 50%;
            overflow: hidden;
            .winnersPodiumCard-imgAvatar{
                width: 100%;
            }
        }

        .winnersPodiumCard-userName {
            color: #fff;
            font-size: 1.8rem;
            font-weight: 900;
            letter-spacing: .15rem;
            margin: 0 0 10px;
        }

        .winnersPodiumCard-unityCoinsArea {
            @include displayFlex(row, space-between, center);
            margin-top: 5px;
            width: 190px;


            .winnersPodiumCard-unityArea,
            .winnersPodiumCard-CoinsArea {
                @include displayFlex(row, center, center);

                .winnersPodiumCard-unityCoinsAreaIcon {
                    width: 20px;
                    height: 20px;
                    margin: 0 5px;
                }

                .winnersPodiumCard-unityTitle {
                    font-size: .9rem;
                    color: #fff;
                    font-weight: normal;
                }
            }
        }
    }

    .winnersPodiumCard-reward {
        width: 100%;
        height: 40%;
        padding-bottom: 15px;

        @include displayFlex(column, flex-end, center);

        .winnersPodiumCard-rewardImg {
            height: 75%;
        }

        .winnersPodiumCard-rewardTitle {
            color: #fff;
            font-size: .9rem;
        }
    }
}

