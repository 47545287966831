@import './../../Styles/variables';

.bannerLeft {
   
    box-sizing: border-box;
    background-color: $--bcg;
    background-image: url('./../../img/bg-sipat.png');
    background-size: cover;
    background-position: center;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;   
    @media only screen and (min-width: $--bpt-01) {        
       padding: 140px 0 60px;
       justify-content: center;
     } 
     .BannerLeft-downloadBtns{
         align-items: center;
         justify-content: center;
         display: flex;
         flex-direction: row; 
         width: 90%;
            
     }

     .BannerLeft-downloadChangeLanguage{
         margin: 120px 0 0;        
         display: flex;
         justify-content: flex-start;
         align-items: center;
         flex-direction: column-reverse;       
         height: 190px;
         width: 90%;
         @media only screen and (min-width: $--bpt-01) {    
             flex-direction: column;      
           justify-content:space-evenly;
           margin:0
          } 
        .gGHUNE{            
             margin:5px !important
         }
     }
    .bannerLeft-content {
        padding: 0 45px 0 45px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 92%;

        @media only screen and (min-width:375px) {
            padding: 0;
        }

        @media only screen and (height:812px) {
            padding-top: 40px;
        }      
         
            .bannerLeft-dateP{
                box-sizing: border-box;
                color: #fff;
                font-weight: 300;
                font-size: 1.5rem;
                margin-top: 0;   
                display: initial;   
                @media only screen and (min-width: $--bpt-01) {
                    display: none;
                 }         
            }
            .bannerLeft-dateP2{
                font-family: $--primary-font;
                box-sizing: border-box;
                color: #fff;
                font-weight: 400;
                font-size: 2rem;
                margin: 10px;  
                display: none; 
                @media only screen and (min-width: $--bpt-01) {
                    display: initial;
                 }            
            }

        

        .bannerLeft-imgWrapper {
           width: 80%;
            display: flex;
            justify-content: center;
            align-items: center;
                         

            img {
                width: 95%;   

                @media only screen and (min-width:$--bpt-01) {
                    width: 100%;
                    margin-bottom: 20px;
                }
            }
        }
        .bannerLeft-textP {          
            color: $--primary-color;
            font-size: 1.3rem;
            font-weight: bold;
            width: 130%;
            text-align: center;
            margin-bottom: 0;
            box-sizing: border-box;
            @media only screen and (min-width:$--bpt-01) {
              display: none;
            }
            margin-bottom: 0;
        }

      
        .bannerLeft-btnWrapper {
            background: url("./../../img/scrollbutton.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin: 1rem 2rem 0;
            height: 70px;
            width: 70px;
            cursor: pointer;
            border-radius: 50%;   

            @media only screen and (min-width: $--bpt-01) {
               display: none;
            }
        }
    }

    @media only screen and (min-width: $--bpt-01) {
        width: 50vw;
    }
 

}

.bannerLeft.true {
    display: none;

    @media only screen and (min-width: $--bpt-01) {
        display: initial;
    }
}

.bannerLeft.false {
    display: initial;
}