@import '../../Styles/variables';
.loadInitial{
    background-color:$--bcg ;
    box-sizing: border-box;
    margin: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}