@import '../../Styles/variables';

.downloadBtnLeft_desktop {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    box-sizing: border-box;
    color: #fff;
    font-family: $--primary-font;
    background-color: rgb(61, 61, 61);
    border-radius: 8px;
    border: none !important;
    font-size: .5rem;
    width: 240px;
    height: 50px;
    cursor: pointer;
    transition: .2s ease-in-out;
    margin: 10px 5px 5px;

    @media only screen and (min-width: $--bpt-01) {
        width: 250px;
    }

    &:hover {
        background-color: rgb(84, 84, 84);
    }

    .downloadBtnLeft-text {
        margin: 12px 0px;
        text-align: left;
    }

    .downloadBtnLeft-icon {
        width: 25px;
        margin: 0 5px;       
    }
}

.downloadBtnLeft_mobile{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    box-sizing: border-box;
    color: #fff;
    font-family: $--primary-font;
    background-color: rgb(61, 61, 61);
    border-radius: 8px;
    border: none !important;
    font-size: .5rem;
    width: 200px;
    height: 70px;
    cursor: pointer;
    transition: .2s ease-in-out;
    margin: 10px 5px 5px;

   

    &:hover {
        background-color: rgb(84, 84, 84);
    }

    .downloadBtnLeft-text {
        margin: 12px 0px;
        text-align: left;
    }

    .downloadBtnLeft-icon {
        width: 25px;
        margin: 0 5px;       
    }
}

