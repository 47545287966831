@import '../../Styles/variables';

#form {
    position: relative;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    background-color: $--bcg;
    width: 100vw;
    max-height: 158vh;
    min-height: 100vh;
    padding-top: 20px !important;
    overflow: hidden;
    flex-shrink: 0;

    @media only screen and (min-width: $--bpt-01) {
        width: 50vw;
        padding-top: 5px;
    }      

    .form-text {
        font-size: 2rem;
        font-weight: bold;
        font-family: $--primary-font;
        color: $--primary-color;
        margin: 0;
        display: none;

        @media only screen and (min-width: $--bpt-01) {
            display: initial;
            font-size: 1.8rem;   
           // margin-bottom: -15px;    

        }
    }

    .MuiFormControl-root.MuiTextField-root.form-nameinput,
    .MuiFormControl-root.MuiTextField-root.form-emailinput {
        width: 80%;
        margin: 2px 0;
        color: $--primary-color !important;

        input,
        label {
            color: $--primary-color !important;
        }


        div::before,
        div::after {
            border-color: $--primary-color !important;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0px 1000px $--bcg inset;
            box-shadow: 0 0 0px 1000px $--bcg inset;

        }

        p {
            @include error;
        }
    }

    .form-countryinput,.form-cityinput{
        p{
            @include error;
        }
    }
}
#form .form-phoneinput{
    p{
        @include error
    }
}

.form-local {
    margin-top: -10px;
    width: 80%;
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: $--bpt-01) {
        flex-direction: row;
        margin-top: -15px;
    }

    .form-countryinput {
        width: 100%;
        margin: 15px 15px 0 0 !important;
        color: $--primary-color !important;
        .MuiInputBase-root {
            color: $--primary-color !important;
        }

        @media only screen and (min-width: $--bpt-01) {
            width: 33%;          
        }

        input,
        label {
            color: $--primary-color !important;
        }

        .MuiInput-underline::before,
        .MuiInput-underline::after {
            border-color: $--primary-color !important;
        }

        .MuiSvgIcon-root.MuiSelect-icon {
            color: $--primary-color;
        }

        #standard-select-currency-native {
            color: $--primary-color;
            border-color: $--primary-color !important;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0px 1000px $--bcg inset;
            box-shadow: 0 0 0px 1000px $--bcg inset;

        }

    }

    .form-estCid {       
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 5px;

        @media only screen and (min-width: $--bpt-01) {
            margin-top: 0;
        }



        .form-cityinput {
            width: 48%;
            margin: 15px 0 0 !important;
            color: #FF6505;

        }

        .form-cityinput input,
        .form-cityinput label,
        .form-cityinput .MuiInputBase-input {
            color: #FF6505 !important;
        }

        .form-cityinput .MuiInput-underline::before,
        .form-cityinput .MuiInput-underline::after {
            border-color: #FF6505 !important;
        }

        .form-cityinput .MuiSvgIcon-root.MuiSelect-icon {
            color: #FF6505;
        }

        .form-cityinput #standard-select-currency-native {
            color: #FF6505;
            border-color: #FF6505 !important;
        }

        .form-cityinput div::before,
        .form-cityinput div::after {
            border-color: #FF6505 !important;
        }

        .form-cityinput input:-webkit-autofill,
        .form-cityinput input:-webkit-autofill:hover,
        .form-cityinput input:-webkit-autofill:focus,
        .form-cityinput input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0px 1000px #F0F0F0 inset;
            box-shadow: 0 0 0px 1000px #F0F0F0 inset;
        }
    }
}

#form {
    .form-passwordAreaError{  
        display: flex;
        flex-direction: column;     
        width: 80%;
    }
    .form-passwordArea {
        margin-top: -5px;
        display: flex;
        flex-direction: column;
        width: 100%;       
        
        @media only screen and (min-width: $--bpt-01) {
        margin-top: -15px; 
         
        }

        .form-passwordcheck {
            display: initial;
        }

        .form-passwordcheckhidden {
            display: none;
        }

        @media only screen and (min-width: $--bpt-01) {
            flex-direction: row;
            justify-content: space-between;
        }

        .form-passwordinput {
            width: 100%;
            margin: 15px 0 0 !important;
            color: #FF6505;


            @media only screen and (min-width: $--bpt-01) {
                width: 48%;
            }
        }

        .form-passwordinput input,
        .form-passwordinput label {
            color: #FF6505 !important;
        }

        .form-passwordinput div::before,
        .form-passwordinput div::after {
            border-color: #FF6505 !important;
        }

        .form-passwordinput input:-webkit-autofill,
        .form-passwordinput input:-webkit-autofill:hover,
        .form-passwordinput input:-webkit-autofill:focus,
        .form-passwordinput input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0px 1000px #F0F0F0 inset;
            box-shadow: 0 0 0px 1000px #F0F0F0 inset;
        }
    }

    .formPhone-textField {
        width: 100%;
        margin: 15px 0 0 !important;
        color: $--primary-color;

        input,
        label {
            color: $--primary-color !important;
        }


        div::before,
        div::after {
            border-color: $--primary-color !important;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0px 1000px $--bcg inset;
            box-shadow: 0 0 0px 1000px $--bcg inset;
        }
    }

    .form-radiophoneArea {    
        margin-top: -5px;
        width: 80%;
        display: flex;
        flex-direction: column-reverse;

        @media only screen and (min-width: $--bpt-01) {
            margin-top: -30px;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: flex-end;            
           
        }


        .form-phoneinput {
            width: 100%;
            margin: 15px 0 0 !important;
            color: $--primary-color;

            @media only screen and (min-width: $--bpt-01) {
                width: 48%;
            }

            input,
            label {
                color: $--primary-color !important;
            }


            div::before,
            div::after {
                border-color: $--primary-color !important;
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
                -webkit-box-shadow: 0 0 0px 1000px $--bcg inset;
                box-shadow: 0 0 0px 1000px $--bcg inset;
            }
        }

        .form-radio {
            margin: 20px 0 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;

            @media only screen and (min-width: $--bpt-01) {
                width: 48%;
                height: 100%;                  
                justify-content: flex-end;
                align-items: center;
               
              
            }
        }

    }
.form-termsError{  
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


    .form-terms {
        display: flex;
        justify-content: center;
        width: 80%;
        align-items: center;
        margin-bottom: 5px;
        margin-top: 15px;

        .form-termsP {
            text-align: start;
            color: $--grey;
            margin-left: -15px;
           
          

            a {
                text-decoration: none;
                color: inherit;
            }

            span {
                text-decoration: underline;

            }
        }
    }

    .form-selectArea_false {
        margin-top: -10px;
        display: flex;
        flex-direction: column;
        width: 80%;
        p{
            @include error;
            margin-bottom:-10px;
        }

        .form-select {
            #demo-customized-select {
                display: flex;
                justify-content: flex-start;
                background-color: $--bcg;
                padding: 0;
                color: rgb(116, 116, 116);
            }

            .form-inputArea {
                padding: 10px;
                height: 40px;
            }

            .MuiInputLabel-animated {
                margin: 0 10px;
                z-index: 5;
                background-color: $--bcg;
            }

            .MuiInput-underline::before {
                border: 1px solid $--grey;
                height: 50px;
                border-radius: 15px;
            }

            .MuiInput-underline::after {
                display: none;
            }

            .MuiSvgIcon-root.MuiSelect-icon {
                margin-right: 10px;
            }
        }

        .form-label {
            margin: 10px 0;
        }

    }

    .form-selectArea_true {
        display: none;
    }

    .form-recaptchafinishbtnArea {
        margin-top: -10px;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        .form-btnfinish {
            background-color: $--primary-color;
            border: none;
            border-radius: 15px;
            width: 300px;
            height: 60px;
            margin: 15px 0 15px 5px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            overflow: hidden;
            transition: all .2s ease-in-out;
            &:hover{
                background-color: rgb(249, 132, 77);
                transform: scale(1.01)               
            }

            .form-btnfinishtext {
                color: #fff;
                text-transform: uppercase;
                font-family: $--primary-font;
            }
            .form-btnimgWrapper{              
                width: 25px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                .form-btnimg {
                    width: 45px;
                }
    
            }
          
        }
    }
}

.grecaptcha-badge {
    bottom: 120px !important;
    background-color: transparent;


}

.rc-anchor {
    background: #000 !important;
}

.formsTermsHelper {
    @include error;
    text-align: center;
    margin: -10px 0 20px;  
    width: 80%;

    @media only screen and (min-width: $--bpt-01) {
        margin: -15px 0 0;        
    }

}

ul {
    margin-top: 5px;
}


.form-passworderror {
    margin-bottom: 10px; 
    display: flex;
    justify-content: flex-start;
    width: 80%;
   

    li {
       
        @include error;        
        text-align: left !important;
        justify-content: flex-start;
       

        span {
            opacity: 1;
        }

        &:not([class*="invalid"]) {
            display: none;
        }

        svg {
            display: none;

        }
    }
}

#form .MuiFormControl-root.MuiTextField-root.form-nameinput {
   
    @media only screen and (min-width: $--bpt-01) {
        margin-top: -15px;
    }
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.87) !important;

}

.MuiFormLabel-root.Mui-focused {
    color: rgba(0, 0, 0, 0.87) !important;
}

#form {
  position: relative;

    .form-load {
        z-index: 2;
        position: absolute;
       
        margin: 0; 
        box-sizing: border-box;
        width: 100vw;
        height: 100%;
        bottom:10px ;
        background-color: #f0f0f0a8;      
        justify-content: center;
        align-items: center;
      

        @media only screen and (min-width: $--bpt-01) {
            padding-top: 15%;
            top: 0;
            width: 50vw;
            height: 100%;           
        }
    }

}
.MuiSelect-select:focus{
    &:focus{

        background-color: $--bcg !important
    }

}

@media only screen and (min-width: $--bpt-01) {
    p { 
        @include error;
    }
    .error-p:nth-child(2) {
        margin: 20px;
        margin-top: 8%;
    }
    .error-p:nth-child(4) {
        margin-top: 8%;
        margin: 20px;
    }
    
    .error-p:nth-child(3) {
       margin: 4%;
    }
}

p { 
    @include error;
}

.error-p:nth-child(2) {
    margin: 20px;
    margin-top: 4%;
}

#error-phone {
    margin-top: 40px;
}