@import '../../Styles/variables';

.winnersCard {
    width: 90vw;
    height: 15.8vh;
    background-color: #fff;
    margin: 12px;
    border-radius: 20px;
    border: 2px solid #FF7700;
    @include displayFlex(row, center, center);

    @media only screen and (min-width: $--bpt-01) {
        width: 20vw;
        height: 11.5vh;
        min-width: 380px;
        padding-left: 5px;
        min-height: 86px;
        margin: 3px 12px;
    }

    .winnersCard-left {
        width: 13%;
        height: 90%;
        margin: 10px;
        @media only screen and (width:375px) {
            width: 15%;             
        }

        @media only screen and (min-width:411px) {
            width: 17%;
        }

        @media only screen and (min-width: $--bpt-01) {
            margin: 15px;
            height: 100%;
        }


        @include displayFlex(column, center, center);

        .winnersCard-position {
            color: $--primary-color;
            font-weight: 900;
            font-size: 3.5vh;
            margin-bottom: -3px;


        }

        .winnersCard-profileImgWrapper {
            width: 9vh;
            height: 9vh;
            border: 3px solid $--primary-color;
            border-radius: 70%;
            background-color: rgb(226, 226, 226);
            @media only screen and (width:375px) {
                width: 8vh;
                height: 8vh;;              
            }

            @media only screen and (min-width: $--bpt-01) {
                width: 6.5vh;
                height: 6.5vh;
            }

            .winnersCard-imgAvatar {
                width: 100%;
            }
        }
    }

    .winnersCard-Right {

        width: 87%;
        @include displayFlex(column, space-evenly, center);
        height: 100%;
        margin: 0 10px;



        .winnersCard-name {
            font-size: 1.5rem;
        }

        .winnersCard-coinsUnityArea {
            @include displayFlex(row, center, center);
            margin: 2px;

            .winnersCard-unityArea,
            .winnersCard-coinsArea {
                @include displayFlex(row, center, center);

                margin: 0 5px;
            }
        }

        .unityIcon,
        .coinIcon {
            margin: 0 2px;
        }

        .winnersCard-reward {
            color: $--primary-color;
            font-weight: 900;
        }
    }

}

#root>div>div>div.winnersLeft>div.winnersLeft-top>div.winnersLeft-cardsArea>div>div.sc-pNWdM.jtxqcn.rec.rec-carousel>button.sc-jSFjdj.fNUbXP.rec.rec-arrow.rec.rec-arrow-right,
#root>div>div>div.winnersLeft>div.winnersLeft-top>div.winnersLeft-cardsArea>div>div.sc-pNWdM.jtxqcn.rec.rec-carousel>button.sc-jSFjdj.fNUbXP.rec.rec-arrow.rec.rec-arrow-left,
#root>div>div>div.winnersLeft>div.winnersLeft-top>div.winnersLeft-cardsArea>div>div.sc-pNWdM.jtxqcn.rec.rec-carousel>button.sc-jSFjdj.entGpB.rec.rec-arrow.rec.rec-arrow-right,
#root>div>div>div.winnersLeft>div.winnersLeft-top>div.winnersLeft-cardsArea>div>div.sc-pNWdM.jtxqcn.rec.rec-carousel>button.sc-jSFjdj.entGpB.rec.rec-arrow.rec.rec-arrow-left {
    opacity: 0;
}