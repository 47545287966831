@import '../../Styles/variables';

.CardPremiacao {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    @media only screen and (min-width: $--bpt-01) {  
        margin: 100px 20px 20px;
    }
    .CardPremiacao-body {
        height: 288px;
        width: 310px;
        border: solid 3px #FF6505;
        border-radius: 20px;
        background: #F0F0F0 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 15px #00000042;
        margin: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        .CardPremiacao-imgwrapper {
            width: 75px;
            display: flex;
            justify-content: center;
            position: relative;
           top: 35px;

            img {
                width: 400%;
            }
        }

        .CardPremiacao-rank {
            color: $--primary-color;
            font-family: $--primary-font;
            font-weight: 900;
            font-size: 3rem;
            margin-bottom: 10px;
            text-transform: uppercase;  
        }

        .CardPremiacao-reward {
            color: $--primary-color;    
            font-family: $--primary-font;
            font-size: 1.4rem;
            font-weight: 600;
            margin: 0 0 30px;
        }
    }
    p{
        margin: 0;
        color:#707070;
        font-size: .65rem;
    }
}
.CardPremiacao:nth-child(1){
  margin-top: 40px;
  @media only screen and (min-width: $--bpt-01) {     
      margin-top: 100px;
  }
}
.CardPremiacao:nth-child(4) {
    .CardPremiacao-imgwrapper {
       top: -25px;
       width: 90px;
    }
}
.CardPremiacao:nth-child(5) {
    .CardPremiacao-imgwrapper {
        top: 100px;
       width:100px;
    }
}
.CardPremiacao:nth-child(6) {
    .CardPremiacao-imgwrapper {
        top: 0;
       width:60px;
    }
}