@import '../../Styles/variables';

.finishScreen {
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    background-color: $--bcg;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 30px 0 30px;

    @media only screen and (min-width: $--bpt-01) {
        padding: 80px 0 80px;
    }

    button {   
          
            width: 60%;
            height: 45px;
            overflow: hidden;
       
    }

    .finishScreen-imgNexaLogo {
        max-width: 40%;
        margin-bottom: 25px;
        display: initial;


        @media only screen and (min-width: $--bpt-01) {
            display: none;
        }
    }


    .finishScreen-prontoWrapper {
        width: 60%;

        @media only screen and (min-width: $--bpt-01) {
            margin-bottom: -10px;
        }

        .finishScreen-imgPronto {
            margin-left: 5px;
            width: 90%
        }
    }

    .finishScreen-downP {
        color: $--primary-color;
        font-family: $--primary-font;
        font-weight: bold;
        font-size: 1.4rem;
        margin: 10px
    }

    .finishScreen-DispP {
        color: $--primary-color;
        font-size: .8rem;
        margin: 10px;
    }

    .finishScreen-iconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        width: 40%;
        height: 50px;


        .finishScreen-imgIcon {
            height: 70%;
            margin: 0 10px;
        }
    }

    @media only screen and (min-width: $--bpt-01) {
        width: 50vw;
        padding-top: 0;
    }
}
.finishScreen-BtnArea{
  
    width: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
}
.finishScreen-iconArea{
    margin: 10px;
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
}
.finishScreen-DownloadArea{
    width: 120%;
    
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .downloadBtnLeft_mobile{
       margin:  0 5px !important;
        height: 70px;
        width: 230px;
        margin-top: 20px;
     
        background-color: $--primary-color;
        &:hover {           
            background-color: rgb(249, 132, 77); 
        }
    
    }
  
}