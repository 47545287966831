@import '../../Styles/variables';

.modalError_true {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    display: flex;
    position: absolute;
    align-items: flex-end;
    padding-bottom: 190px;
    justify-content: center;
    width: 100%;   
    background-color: rgba(24, 24, 24, 0.575);
    z-index: 5;
    align-self: center;
    transition: .3s ease-in-out;
    height: 110%;

    @media only screen and (min-width: $--bpt-01) {
        justify-self: flex-start;
        align-items: center;
        padding-bottom: 0;
      
    }

    .modalError-modal_true {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        width: 292px;
        height: 380px;
        padding: 50px 0 ;
        box-shadow: 0px 1px 32px 0px rgba(0, 0, 0, 0.15);
        background-color: #ffffff;
        border-radius: 23px;
        transition: .3s ease-in-out;
    }
}

.modalError_false {
    display: none;


    .modalError-modal_false {
        display: none;
      
    }
}

.modalError-btn {
    background-color: $--primary-color;
    font-family: $--primary-font;
    color: #fff;
    font-weight: 900;
    text-transform: capitalize;
    font-size: 1rem;
    width: 140px;
    height: 60px;
    border-radius: 15px;
    border: none;
    transition: all .3s ease-in-out;
    cursor: pointer;
    margin-top: 10px;
  

    &:hover {
        transform: scale(1.1);
        background-color: rgb(249, 132, 77); 
    }

}
.modalError-title{
    color: $--primary-color;
    font-family: $--primary-font;
    font-weight: 900;
    font-size: 2.8rem;   
}
.modalError-title_1{
    color: $--primary-color;
    font-family: $--primary-font;
    font-weight: 900;
    margin-bottom: 5px;
    font-size: 1.5rem;   
}

.modalError-FullText{
    margin-top: 20px;
}
.modalError-text{
    margin: 2px 0 20px;
    padding: 0 22px;
    color: black;
    font-family: $--primary-font;
    font-weight: normal;
    font-size: 1rem;   
}

.ModalErrorImg{
    width: 25%;
}