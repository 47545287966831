@import './../../Styles/variables';
.home{
    display: flex;
    flex-direction: column;
    width: 100vw;
    overflow: hidden;
    @media only screen and (min-width: $--bpt-01) {
        flex-direction: row;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        
    }
    
}