
.App {
  text-align: center;
  width: 100vw;
  overflow: hidden;
 
}
input:-webkit-autofill
{
 -webkit-text-fill-color: #FF6505 !important;
}
.dial-code{
  display: none;
}

::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ff650557; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #707070; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4b4b4b; 
}