@import '../../Styles/variables';

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.winnersLeft {
    background-color: #F0F0F0;
    width: 100vw;
    height: 130vh;
    padding: 0 10px;
    @include displayFlex(column-reverse, space-between, flex-start);

    @media only screen and (min-width: $--bpt-01) {
        width: 65vw;
        height: 100vh;
        @include displayFlex(column, flex-start, flex-start);
    }

    .winnersLeft-top {
        @include displayFlex(column, space-between, center);
        width: 100%;
        height: 90%;
        align-self: flex-end;  
        @media only screen and (min-width: $--bpt-01) {

            @include displayFlex(column, space-evenly, center);
        }


        .winnersLeft-Header {
            @include displayFlex(column, flex-start, center);
            height: 30%;
            width: 100%;

            @media only screen and (min-width: $--bpt-01) {

                @include displayFlex(row, space-around, flex-start);
            }

            .winnersLeft-filterArea {
                @include displayFlex(column, space-between, center);
                height: 120px;
               
                @media only screen and (min-width: $--bpt-01) {
                    @include displayFlex(column, space-between, flex-start);
                }

                .winnersLeft-title {
                    @include displayFlex(column, flex-start, center);
                    margin-top: 5px;
                    color: $--primary-color;
                    font-family: $--primary-font;
                    font-weight: 900;
                    font-size: 3rem;

                    @media only screen and (min-width: $--bpt-01) {
                        font-size: 4.8vw;
                    }
                }

                .winnersLeft-filter {
                    @include displayFlex(column, space-between, center);
                    width: 100%;
                    margin: 10px;
                    @media only screen and (min-width: $--bpt-01) {
                        @include displayFlex(row, space-between, center);
                        font-size: 4.8vw;
                        margin:0;
                    }

                    .winnersLeft-selectUnity {
                        font-family: $--primary-font;
                        font-size: 1rem;
                        padding-left: 5px;
                        margin: 5px;
                        background-color: #fff;
                        width: 60vw;
                        height: 35px;
                        border: 1px solid #D2D8E2;
                        border-radius: 6px;
                        @media only screen and (min-width: $--bpt-01) {
                            width: 15vw;
                            margin: 0 15px;
                            height: 35px;                     
                        }
                    }

                    .winnersLeft-selectP {
                        color: $--primary-color;
                        font-size: 1rem;
                    }
                }
            }

            .winnersLeft-logosArea {
               margin: 20px 0;
               @media only screen and (min-width: $--bpt-01) {
                margin-bottom: 20px;                         
            }
               

                @include displayFlex(row, center, flex-start);               

                .winnersLeft-logoNexa {
                    margin: 0 10px;
                    height: 160px;
                    max-height: 18vh;
                }

                .winnersLeft-logoZH {
                    height: 90px;
                    align-self: center;

                    @media only screen and (min-width: $--bpt-01) {
                        height: 100px;
                        max-height: 16vh;

                    }

                }
            }


        }

        .winnersLeft-cardsArea {
            align-self: center;
            width: 98vw;
            margin-bottom: 20px;
            @include displayFlex(row, flex-start, flex-start);

            @media only screen and (min-width: $--bpt-01) {
                width: 58vw;
                margin-bottom: 0;              
            }

        }

    }
    .alice-carousel__wrapper{
        @media only screen and (min-width: $--bpt-01) {
            overflow:auto;          
        }
      
    }

    .winnersLeft-bottom {
        width: 100%;
        height: 10%;
        @include displayFlex(row, center, center);
       
    }

}

#root>div>div>div.winnersLeft>div.winnersLeft-top>div.winnersLeft-cardsArea>div>div.sc-iqAclL.iwgzHc.rec.rec-pagination {
    display: none;
}

.entGpB:hover:enabled,
.entGpB:hover:disabled,
.entGpB:focus:enabled,
.entGpB:focus:disabled,
.fNUbXP:hover:enabled,
.fNUbXP:hover:disabled,
.fNUbXP:focus:enabled,
.fNUbXP:focus:disabled {
    background-color: transparent !important;
    box-shadow: none !important;
}

#root>div>div>div.winnersLeft>div.winnersLeft-top>div.winnersLeft-cardsArea>div>div.sc-pNWdM.jtxqcn.rec.rec-carousel>button.sc-jSFjdj.entGpB.rec.rec-arrow.rec.rec-arrow-right {
    background-color: transparent !important;
    box-shadow: none !important;
}

#root>div>div>div.winnersLeft>div.winnersLeft-top>div.winnersLeft-cardsArea>div>div.sc-pNWdM.jtxqcn.rec.rec-carousel>button.sc-jSFjdj.fNUbXP.rec.rec-arrow.rec.rec-arrow-left {
    background-color: transparent !important;
    box-shadow: none !important;
    z-index: 90;
    // display: none;

}

.AYOKJ,
.AYOKJ {
    overflow: visible !important;
}

#root>div>div>div.winnersLeft>div.winnersLeft-top>div.winnersLeft-cardsArea>div>div.sc-pNWdM.jtxqcn.rec.rec-carousel>button.sc-jSFjdj.fNUbXP.rec.rec-arrow.rec.rec-arrow-right {
    background-color: transparent !important;
    box-shadow: none !important;
    z-index: 90;
    // display: none;
}

#root>div>div>div.winnersLeft>div.winnersLeft-top>div.winnersLeft-cardsArea>div>div.sc-pNWdM.jtxqcn.rec.rec-carousel>div {
    overflow: visible !important;
}

#root>div>div>div.winnersLeft>div.winnersLeft-top>div.winnersLeft-cardsArea>div>div.sc-pNWdM.jtxqcn.rec.rec-carousel {

    @media only screen and (min-width:320px) {
        width: 130%;
    }

    @media only screen and (min-width:360px) {
        width: 120%;
    }

    @media only screen and (min-width:375px) {
        width: 115%;
    }

    @media only screen and (min-width:411px) {
        width: 108%;
    }

}

#root>div>div>div.winnersLeft>div.winnersLeft-top>div.winnersLeft-cardsArea>div>div.sc-pNWdM.jtxqcn.rec.rec-carousel>button.sc-jSFjdj.entGpB.rec.rec-arrow.rec.rec-arrow-left {
    position: relative;
    z-index: 90;
    background-color: transparent !important;
    box-shadow: none !important;
    // display: none;
}

.alice-carousel__stage-item{
    width: fit-content !important;
    height: fit-content  !important;
   
  
    align-items: flex-end;
   
}