@import '../../Styles/variables';

.winnersRight {
    z-index: 99;
    background-color: #E6E6E6;
    @include displayFlex(column, space-evenly, center);
    padding-top: 20px;
    width: 100vw;
    height: 140vh;

    @media only screen and (min-width: $--bpt-01) {
        padding-top: 1vh;       
        width: 35vw;
        height: 100vh;   
    }
    
    .winnersRight-headP{
        font-family: $--primary-font;
        color: $--primary-color;
        font-weight: bold;
    }
    .winnersRight-cardsArea{
        @include displayFlex(column, space-evenly, center);
        height: 100%;
    }
}

