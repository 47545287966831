@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,400&display=swap');

//-- Colors
$--bcg:#F0F0F0;
$--primary-color:#FF6505;
$--grey: rgb(53, 53, 53);

//-- Breakpoints
$--bpt-01:800px;

//-- Fonts
$--primary-font:'Montserrat', sans-serif;

//-- Error
@mixin error {
    font-weight: 600;
    font-family: $--primary-font;
    font-size: .8rem;
    color: rgb(252, 0, 0);
  }

@mixin displayFlex($dir,$jc,$ali){
  display: flex;
  flex-direction:$dir;
  justify-content: $jc;
  align-items: $ali;
}